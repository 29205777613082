export const ready = (fn) => {
    // Sanity check
    if (typeof fn !== 'function') return;

    // If document is already loaded, run method
    if (document.readyState !== 'loading') {
        fn();
    } else {
        // Otherwise, wait until document is loaded
        document.addEventListener('DOMContentLoaded', fn);
    }
};

/**
 * jQuery on() alternative
 */
export const on = (selector, eventType, childSelector, eventHandler) => {
    const elements = (selector instanceof Element) ? [selector]
        : ((selector instanceof Array) ? selector : document.querySelectorAll(selector));
    for (const element of elements) {
        element.addEventListener(eventType, /** @type {Event} */ eventOnElement => {
            if (eventOnElement.target.matches(childSelector)) {
                eventHandler(eventOnElement);
            }
        });
    }
};

/**
 * jQuery parents() alternative
 */
export const parents = (/** @type {Node|NodeList|Array} */ nodes, /** @type {string} */ selector) => {
    const matched = [];
    const getNodeParents = (/** @type {Node} */ node, /** @type {string} */ selector) => {
        while ((node = node.parentNode) && node.nodeType !== Node.DOCUMENT_NODE) {
            if (node.nodeType === Node.ELEMENT_NODE && ((selector !== undefined && node.matches(selector)) || selector === undefined)) {
                matched.push(node);
            }
        }
    }

    if (nodes instanceof NodeList || nodes instanceof Array) {
        nodes.forEach((node) => getNodeParents(node, selector));
    } else {
        getNodeParents(nodes, selector);
    }

    return matched;
};
