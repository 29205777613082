import {ready, on, parents} from './utils';

ready(() => {
    // Function-level strict mode syntax
    'use strict';

    initializeVariantSelector();
    initializeCartQuantitySelector();
});

$(document).ready(function ($) {
    $(".light-gallery").lightGallery({
        selector: '.gallery-image'
    });
});

function initializeCartQuantitySelector() {
    // exit if not on cart page
    const form = document.querySelector('.shopping-cart');
    if (form === null) {
        return;
    }

    console.debug('Initializing cart quantity selector...');
    const csrfToken = document.head.querySelector("[name~=csrf-token][content]").content;
    const fieldset = form.querySelector('fieldset');

    on('form', 'change', '.product-quantity > input', async (event) => {
        /** @type {HTMLInputElement} */
        const target = event.target;
        const quantityChange = target.value - Number(target.dataset['previousValue']);
        const data = {
            id: target.dataset['id'],
            variantId: target.dataset['variantId'],
            quantity: quantityChange,
        };

        fieldset.disabled = true;
        const response = await fetch(`/api/order/update-cart`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'X-Requested-With': 'XMLHttpRequest',
                'X-CSRF-Token': csrfToken,
            },
            body: JSON.stringify(data),
            credentials: 'include',
        })

        if (response.ok) {
            const json = await response.json();

            /** @type {HTMLTableRowElement} */
            const container = parents(target, '.cart-item')[0];

            container.querySelector('.product-total span').innerHTML = json['variant-total'];

            form.querySelector('.cart-subtotal > td span').innerHTML = json['subtotal'];
            form.querySelector('.order-shipping > td span').innerHTML = json['shipping'];
            form.querySelector('.order-total > td span').innerHTML = json['total'];

            document.querySelector('.btn-cart > .badge').innerHTML = json['cart-quantity'];
            if (Number(target.value) === 0) {
                container.remove();
                if (form.querySelectorAll('.cart-item').length === 0) {
                    return location.reload();
                }
            }
            target.dataset['previousValue'] = target.value;
            fieldset.disabled = false;
        }
    });
}

function initializeVariantSelector() {
    // exit if not on product page
    const form = document.querySelector('.product-form');
    if (form === null) {
        return;
    }

    console.debug('Initializing variant selector...');

    const csrfToken = document.head.querySelector("[name~=csrf-token][content]").content;

    /** @type NodeListOf<HTMLSelectElement> */
    const optionSelectors = document.querySelectorAll('.product-filters .option-selector');
    const price = document.querySelector('.product-price');

    on(form, 'change', 'select.option-selector', (event) => {
        optionSelectors.forEach((element) => element.setAttribute('disabled', ''));
        const selectedOptions = [...optionSelectors.values()]
            .map((element) => {
                const optionId = element.id.slice(7);
                const value = element.options[element.selectedIndex].value;

                return {id: optionId, selectedValue: value};
            });

        const targetId = event.target.id.slice(7);
        const data = {
            target: targetId,
            options: selectedOptions,
        };

        fetch(form.dataset['optionsUrl'], {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'X-Requested-With': 'XMLHttpRequest',
                'X-CSRF-Token': csrfToken,
            },
            credentials: 'include',
            body: JSON.stringify(data)
        }).then(response => response.json()).then(data => {
            price.innerText = data['price'];
            optionSelectors.forEach((element) => {
                const id = element.id.slice(7);
                if (id !== targetId) {
                    const options = data['options'][id]
                    const selectedOptionValue = element.options[element.selectedIndex].value;
                    element.length = 1;
                    element.selectedIndex = 0;
                    // fill options
                    for (const option of options) {
                        const optionElement = document.createElement('option');
                        optionElement.value = option['id'];
                        if (selectedOptionValue === optionElement.value) {
                            optionElement.selected = true;
                        }
                        optionElement.innerHTML = option['label'];
                        element.append(optionElement);
                    }
                }
                element.removeAttribute('disabled')
            });
        });
    });

    /** @type HTMLInputElement */
    const quantity = document.getElementById('product-quantity');

    on(form, 'click', '.btn-increase-decrease', (event) => {
        const id = event.target.id;
        const currentValue = Number(quantity.value);
        if (id === 'btn-addon-decrease-qty' && currentValue > 1) {
            quantity.value = `${currentValue - 1}`;
        }

        if (id === 'btn-addon-increase-qty') {
            quantity.value = `${currentValue + 1}`;
        }
    });
}
